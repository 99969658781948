<template>
  <div class="w-full">
    <label for="goal" class="leading-7 text-sm font-medium text-gray-500">
      学習科目（複数選択可）
    </label>
    <div class="p-3 rounded-md bg-white text-sm text-gray-400">
      <div v-if="isElementary" class="mb-2">
        <span class="text-gray-400 font-medium">主要科目</span>
        <div class="flex flex-wrap mt-1">
          <div
            v-for="subjectName in elementarySubjects"
            :key="subjectName"
            class="px-3 py-1 mr-3 mb-2 border rounded-full transition-colors duration-300 cursor-pointer"
            :class="dynamicSubjectCss(subjectName)"
            @click="select(subjectName)"
          >
            {{ subjectName }}
          </div>
        </div>
      </div>
      <div v-if="isJuniorHigh" class="mb-2">
        <span class="text-gray-400 font-medium">主要科目</span>
        <div class="flex flex-wrap mt-1">
          <div
            v-for="subjectName in juniorSubjects"
            :key="subjectName"
            class="px-3 py-1 mr-3 mb-2 border rounded-full transition-colors duration-300 cursor-pointer"
            :class="dynamicSubjectCss(subjectName)"
            @click="select(subjectName)"
          >
            {{ subjectName }}
          </div>
        </div>
      </div>
      <div v-if="isHigh" class="mb-2">
        <span class="text-gray-400 font-medium">主要科目</span>
        <div class="flex flex-wrap mt-1">
          <div
            v-for="subjectName in highNormalSubjects"
            :key="subjectName"
            class="px-3 py-1 mr-3 mb-2 border rounded-full transition-colors duration-300 cursor-pointer"
            :class="dynamicSubjectCss(subjectName)"
            @click="select(subjectName)"
          >
            {{ subjectName }}
          </div>
        </div>
      </div>
      <div v-if="isHigh" class="mb-2">
        <span class="text-gray-400 font-medium">理科</span>
        <div class="flex flex-wrap mt-1">
          <div
            v-for="subjectName in highScienceSubjects"
            :key="subjectName"
            class="px-3 py-1 mr-3 mb-2 border rounded-full transition-colors duration-300 cursor-pointer"
            :class="dynamicSubjectCss(subjectName)"
            @click="select(subjectName)"
          >
            {{ subjectName }}
          </div>
        </div>
      </div>
      <div v-if="isHigh" class="mb-2">
        <span class="text-gray-400 font-medium">社会</span>
        <div class="flex flex-wrap mt-1">
          <div
            v-for="subjectName in highSocialSubjects"
            :key="subjectName"
            class="px-3 py-1 mr-3 mb-2 border rounded-full transition-colors duration-300 cursor-pointer"
            :class="dynamicSubjectCss(subjectName)"
            @click="select(subjectName)"
          >
            {{ subjectName }}
          </div>
        </div>
      </div>
      <div>
        <span class="text-gray-400 font-medium">その他</span>
        <div class="flex flex-wrap mt-1">
          <div
            v-for="subjectName in otherSubjects"
            :key="subjectName"
            class="px-3 py-1 mr-3 mb-2 border rounded-full transition-colors duration-300 cursor-pointer"
            :class="dynamicSubjectCss(subjectName)"
            @click="select(subjectName)"
          >
            {{ subjectName }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import MButton from "@/components/form/MButton.vue";
import {
  SubjectName,
  LearningSubject,
  elementarySubjects,
  juniorSubjects,
  highNormalSubjects,
  highScienceSubjects,
  highSocialSubjects,
  otherSubjects
} from "@/entities/learning";
import {
  StudentGrade,
  elementarySchoolGrades,
  juniorHighSchoolGrades,
  highSchoolGrades
} from "@/entities/student";

@Options({
  components: {
    MButton
  },
  emits: ["select"],
  props: {
    grade: String,
    subjects: Array
  }
})
export default class MSubjectsInput extends Vue {
  grade!: StudentGrade;
  subjects: LearningSubject[] = [];
  elementarySubjects: SubjectName[] = elementarySubjects;
  juniorSubjects: SubjectName[] = juniorSubjects;
  highNormalSubjects: SubjectName[] = highNormalSubjects;
  highScienceSubjects: SubjectName[] = highScienceSubjects;
  highSocialSubjects: SubjectName[] = highSocialSubjects;
  otherSubjects: SubjectName[] = otherSubjects;
  combinedSubjectNames: SubjectName[] = []; // 並び替え用に全体を結合した配列

  get isElementary(): boolean {
    return elementarySchoolGrades.includes(this.grade);
  }

  get isJuniorHigh(): boolean {
    return juniorHighSchoolGrades.includes(this.grade);
  }

  get isHigh(): boolean {
    return highSchoolGrades.includes(this.grade);
  }

  dynamicSubjectCss(subjectName: SubjectName) {
    if (this.subjects.some(s => s.name === subjectName)) {
      return {
        "border-primary-500": true,
        "bg-primary-500": true,
        "text-white": true
      };
    } else {
      return { "border-gray-400": true };
    }
  }

  select(subjectName: SubjectName) {
    let nextSubjects: LearningSubject[] = [];
    if (this.subjects.some(s => s.name === subjectName)) {
      nextSubjects = this.subjects.filter(s => s.name !== subjectName);
    } else {
      nextSubjects = [...this.subjects, { name: subjectName, ratio: 1 }];
    }
    // 比率の入力欄と選択・解除欄で教科の順序を合わせるため、整列
    nextSubjects.sort(
      (a, b) =>
        this.combinedSubjectNames.indexOf(a.name) -
        this.combinedSubjectNames.indexOf(b.name)
    );
    this.$emit("select", nextSubjects);
  }

  created() {
    // 整列用の教科名リストを作成
    if (elementarySchoolGrades.includes(this.grade)) {
      this.combinedSubjectNames = [...elementarySubjects];
    } else if (juniorHighSchoolGrades.includes(this.grade)) {
      this.combinedSubjectNames = [...juniorSubjects];
    } else {
      this.combinedSubjectNames = [
        ...highNormalSubjects,
        ...highScienceSubjects,
        ...highSocialSubjects
      ];
    }
    this.combinedSubjectNames = [
      ...this.combinedSubjectNames,
      ...otherSubjects
    ];
  }
}
</script>
